import React, { useRef, useState } from "react";
import "./About.css";
import { motion } from "framer-motion";
import { FaMoneyCheckAlt, FaAward, FaGlobe } from "react-icons/fa";
import paris from "../assets/paris.jpg";
import masjid from "../assets/masjibNew.jpg";

const About = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const togglePlay = () => {
    const video = videoRef.current;

    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }

    setIsPlaying(!isPlaying);
  };
  return (
    <>
      <section className="about" id="about">
        <div className="aboutTop">
          <motion.div
            initial={{ opacity: 0, x: -300 }}
            whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}
          >
            <div className="aboutImg"></div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 300 }}
            whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}
          >
            <div className="aboutText">
              <h4 className="title">About Us</h4>
              <h2>We Provide Best Tour Packages In Your Budget</h2>
              <p>
                AL AMANAT TRAVELS is a company overseen by Allah that has been
                in existence for more than a decade with many successful
                achievements in their operations. ALH ABDULLAHI OLASENI
                KAMALDEEN is the Managing Director/CEO of the company. He has
                been managing the company with his Western and Islamic
                knowledge, along with his expertise gained from various aviation
                courses. He receives support from a dedicated team of staff
                members who are also certified in IATA and other courses.
                Together, they make the company stand out among others. AL
                AMANAT TRAVELS is an accredited IATA agent company and a member
                of leading travel organizations worldwide. When doing business
                with us, you will never regret it because we lead, others
                follow.
              </p>
              <div className="image">
                <img src={paris} alt="paris" />
                <img src={masjid} alt="masjid" />
              </div>
              <button className="aboutBtn">
                <a
                  href={`https://api.whatsapp.com/send?phone=${"7089451747"}&text=I%20am%20ready%20to%20embark%20on%20my%20journey%20with%20your%20company`}
                >
                  Apply Now
                </a>
              </button>
            </div>
          </motion.div>
        </div>
        <div className="aboutBottom">
          <div className="box">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}
            >
              <div className="icon">
                <FaMoneyCheckAlt />
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}
            >
              <div className="detials">
                <h3>Competitive Pricing</h3>
                <h5>
                  Discover unbeatable prices with our competitive pricing. Book
                  now and save!
                </h5>
              </div>
            </motion.div>
          </div>
          <div className="box">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}
            >
              <div className="icon">
                <FaAward />
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}
            >
              <div className="detials">
                <h3>Best Services</h3>
                <h5>
                  Experience the best services tailored for your needs. Book
                  with confidence.
                </h5>
              </div>
            </motion.div>
          </div>
          <div className="box">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}
            >
              <div className="icon">
                <FaGlobe />
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}
            >
              <div className="detials">
                <h3>Worldwide Coverage</h3>
                <h5>
                  Unleash your wanderlust with our worldwide coverage. Explore
                  limitless possibilities.
                </h5>
              </div>
            </motion.div>
          </div>
        </div>
        <div className="certificate">
          <img
            src="https://res.cloudinary.com/dvo8xhx6r/image/upload/v1684245136/AGIKgqOPB5bjsMVz4SC9gCKJ5Qf-rfk75aRhMmJxKWoX6g_s900-c-k-c0x00ffffff-no-rj_uupifk.jpg"
            alt="iata"
          />
          <img
            src="https://res.cloudinary.com/dvo8xhx6r/image/upload/v1684245197/NANTA-960x698_kdh9d9.jpg"
            alt="nanta"
          />
          <img
            src="https://res.cloudinary.com/dvo8xhx6r/image/upload/v1684245310/ARBm59zA_400x400_utlvoo.jpg"
            alt="nahcon"
          />
          <img
            src="https://res.cloudinary.com/dvo8xhx6r/image/upload/v1684245401/E-XHYA_VIAI6rng_ag41ch.png"
            alt="cac"
          />
          <img
            src="https://res.cloudinary.com/dvo8xhx6r/image/upload/v1684302984/ryoSiNn8_400x400_xjaz2q.png"
            alt="cac"
          />
          <img
            src="https://res.cloudinary.com/dvo8xhx6r/image/upload/v1684309475/800px-International_Civil_Aviation_Organization_logo.svg_akfhuy.png"
            alt="cac"
          />
        </div>
        <section className="gallery">
          <h1 className="subTitle">OUR GALLERY</h1>
          <div className="videogrid">
            <div className="videoContainer">
              <img
                src="https://res.cloudinary.com/dvo8xhx6r/image/upload/v1684245071/PHOTO-2023-04-21-03-14-19_dmodsc.jpg"
                alt="masjid"
              />
            </div>
            <div className="videoContainer">
              <video
                src="https://res.cloudinary.com/dvo8xhx6r/video/upload/v1684245091/VIDEO-2023-03-16-19-51-32_r6urhm.mp4"
                autoPlay
                loop
                muted
              ></video>
            </div>
            <div className="videoContainer">
              <video
                src="https://res.cloudinary.com/dvo8xhx6r/video/upload/v1683756867/0510_1_xrcmuj.mov"
                autoPlay
                loop
                muted
              ></video>
            </div>
            <div className="videoContainer">
              <video
                src="https://res.cloudinary.com/dvo8xhx6r/video/upload/v1684303965/2a20bd3c-3d40-4898-a500-daab70cda305_kliru5.mp4"
                autoPlay
                loop
                muted
              ></video>
            </div>
            <div className="videoContainer" onClick={togglePlay}>
              <video
                src="https://res.cloudinary.com/dvo8xhx6r/video/upload/v1683756871/0510_3_xvyc1d.mov"
                autoPlay
                loop
                // muted
                ref={videoRef}
              ></video>
            </div>
            <div className="videoContainer">
              <video
                src="https://res.cloudinary.com/dvo8xhx6r/video/upload/v1683756717/0510_2_k6m4sr.mov"
                autoPlay
                loop
                muted
              ></video>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default About;
