import React, {useState} from "react";
import "./Testimonials.css";
import testy from "../clients";
import { FaArrowLeft, FaArrowRight, FaQuoteLeft } from "react-icons/fa";

const Testimonials = () => {
  const [select, setSelect] = useState(0)
  const dataLength = testy.length

  return (
   <>
    <section className="testy" id="testy">
      <h4 className="title">Testimonials</h4>
      <h1 className="subTitle">What Say Our Clients</h1>
      <div className="client">
            <span><FaArrowLeft style={{color : 'rgb(255, 94, 0)', fontSize: '2rem', cursor: 'pointer'}} onClick={() => {
              select === 0
              ? setSelect(dataLength - 1)
            : setSelect ((prev) => prev -1)}}/></span>
            <div className="testi">
              <img src={testy[select].url} alt="" className="clientImg" />
              <p>{testy[select].testimonial}</p>
              <FaQuoteLeft style={{color : 'rgb(255, 94, 0)', fontSize: '30px'}}  className='quote'/>
              <h4>{testy[select].name}</h4>
              <h5>{testy[select].profession}</h5>
            </div>
            <span><FaArrowRight style={{color : 'rgb(255, 94, 0)', fontSize: '2rem', cursor: 'pointer'}} onClick={() => {
              select === dataLength -1
              ? setSelect(0)
              : setSelect( (prev) => prev + 1)
            }}/></span>
      </div>
    </section>
   </>
  );
};

export default Testimonials;
