import React, { useEffect, useState } from "react";
import "./SubHome.css";
import { homeCarusole } from "../Data";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const SubHome = () => {
  const [current, setCurrent] = useState(0);
  const dataLength = homeCarusole.length;
  const [autoPlay, setAutoPlay] = useState(true);
  let timeOut = null;

  useEffect(() => {
    const timeOut = autoPlay && setTimeout(() => {
      slideLeft();
    }, 3000);
  
    return () => {
      clearTimeout(timeOut);
    };
  });

//   useEffect(() => {
//     timeOut =
// autoPlay &&
//       setTimeout(() => {
//         slideLeft();
//       }, 3000);
//   });

  const slideLeft = () => {
    setCurrent(current === 0 ? dataLength - 1 : current - 1);
  };

  const slideRight = () => {
    setCurrent(current === dataLength - 1 ? 0 : current + 1);
  };
  return (
    <div className="home" id="home">
      <div
        className="carusole_wrapper"
        onMouseEnter={() => {setAutoPlay(false); clearTimeout(timeOut)}}
        onMouseLeave={() => setAutoPlay(true)}
      >
        {homeCarusole.map((item, index) => {
          return (
            <div
              className={index === current ? "main main-active" : "main"}
              style={{ backgroundImage: `url(${item.image})` }}
              key={index}
            >
              <h1 className="name">{item.title}</h1>
              <h3>{item.subtitle}</h3>
              <button><a href={`https://api.whatsapp.com/send?phone=${"7089451747"}&text=I%20am%20ready%20to%20embark%20on%20my%20journey%20with%20your%20company`}>Book Now</a></button>
            </div>
          );
        })}
        {/* // {`https://api.whatsapp.com/send?phone=${"8060467676"}`} */}
        <span
          className="controlLeft"
          onClick={() => {
            slideLeft();
          }}
        >
          <FaArrowLeft />
        </span>
        <span
          className="controlRight"
          onClick={() => {
            slideRight();
          }}
        >
          <FaArrowRight />
        </span>
        <div className="carusole_pagination">
          {homeCarusole.map((_, index) => {
            return (
              <div
                key={index}
                className={
                  index === current
                    ? "pagination pagination-active"
                    : "pagination"
                }
                onClick={() => setCurrent(index)}
              ></div>
            );
          })}
        </div>
      </div>
      {/* <div className='main'>
        <h1 className='name'>AL-AMANAT</h1>
        <h3>Travels & Services LTD</h3>
        <button>Apply now</button>
        </div> */}
    </div>
  );
};

export default SubHome;
