import "./App.css";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./components/About/About";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import Services from "./components/Services/Services";
import Testimonials from "./components/Testimonials/Testimonials";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Contact Us/Contact";


function App() {
  return (
    
      <div className="App">
        <Header />
        <Home />
        <About />
        <Services />
        <Testimonials />
        <Contact />
        <Footer />
      </div>
  );
}

export default App;
