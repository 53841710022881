import React, { useState } from "react";
import "./Header.css";
import { FaBars } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import colored from "../assets/colored.png";
const Header = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  return (
    <div className="head">
      <div className="header">
        <img src={colored} alt="logo" className="logo" />
        <button
          className="navToggler"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          {isNavExpanded ? <RxCross1 /> : <FaBars />}
        </button>
        <nav
          className={isNavExpanded ? "navBar expanded" : "navBar"}
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          <ul className="navList">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="#about">About Us</a>
            </li>
            <li>
              <a href="#service">Service</a>
            </li>
            <li>
              <a href="#testy">Testimonials</a>
            </li>
            <li>
              <a href="#contact">Contact Us</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Header;
