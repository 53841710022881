import React from "react";
import "./Services.css";
import { motion } from "framer-motion";
import { FaRoute, FaTicketAlt, FaHotel, FaPassport } from "react-icons/fa";

const Services = () => {
  return (
    <>
      <section className="service" id="service">
        <h4 className="title">Services</h4>
        <h1 className="subTitle">Tours & Travel Services</h1>
        <div className="boxes">
          <motion.div
            initial={{ scale: 0 }}
            whileInView={{ scale: 1, transition: { duration: 0.5 } }}
          >
            <div className="eachBox">
              <div className="iconImg">
                <FaRoute />
              </div>
              <h3>Travel Guide</h3>
              <p>
              Explore with our travel guide for an unforgettable journey and experiences.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ scale: 0 }}
            whileInView={{ scale: 1, transition: { duration: 0.5 } }}
          >
            <div className="eachBox">
              <div className="iconImg">
                <FaTicketAlt />
              </div>
              <h3>Ticket Booking</h3>
              <p>
              Relax as we handle your ticket booking efficiently, ensuring your comfort.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ scale: 0 }}
            whileInView={{ scale: 1, transition: { duration: 0.5 } }}
          >
            <div className="eachBox">
              <div className="iconImg">
                <FaHotel />
              </div>
              <h3>Hotel Booking</h3>
              <p>
              Sit back, relax, and indulge in comfort with our seamless hotel booking services.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ scale: 0 }}
            whileInView={{ scale: 1, transition: { duration: 0.5 } }}
          >
            <div className="eachBox">
              <div className="iconImg">
                <FaPassport />
              </div>
              <h3>Visas Processing</h3>
              <p>
              Relax as we handle visas, ensuring a seamless and professional experience.
              </p>
            </div>
          </motion.div>
        </div>

        <h1 className="subTitle">Hajj Packages</h1>
        <h4>2024 HAJJ is selling fast</h4>
        <h5>
          The basic package is already oversubscribed!!! Endeavor to paid up on
          or before January 15 to guarantee your slot!
        </h5>
        <div className="plans">
        <motion.div
            initial={{ scale: 0 }}
            whileInView={{ scale: 1, transition: { duration: 0.5 } }}
          >
          <div className="plan">
            <h3 className="packTitle">BASIC PACKAGE</h3>
            <p>4 IN A ROOM #5,500,000</p>
            <p>2 IN A ROOM - #6,700,000 <br /><span style={{color : 'rgb(255, 94, 0)'}}>This allow husband and wife to live together in a room </span></p>
              <div className="features">
              <p>Accommodation: 4 or 2 in a room ; A 3-star hotel of less than 15 minutes walk to Haram in Makkah and 3 star hotel of less than 5 minutes walk to Haram in Madinah.</p>
              <p>Airline Return Economy Ticket </p>
              <p>Visa procurement</p>
              <p>2 Meals Daily for those who are interested in (Breakfast and Dinner) in Makkah only...50,000</p>
              <p>Local transportation in (Makkah & Madinah)</p>
              <p>Visitation to historical sites</p>
              <p>Tent D Accommodation in Muna and Arafat</p>
              </div>
              <button className='bookBtn'><a href={`https://api.whatsapp.com/send?phone=${"7089451747"}&text=I%20am%20ready%20to%20embark%20on%20my%20journey%20with%20your%20company`}>Book Now</a></button>
          </div>
          </motion.div>
          <motion.div
            initial={{ scale: 0 }}
            whileInView={{ scale: 1, transition: { duration: 0.5 } }}
          >
          <div className="plan">
            <h3 className="packTitle">PREMIUM PACKAGE</h3>
            <p>4 IN A ROOM - #10,600,000</p>
            <p>2 IN A ROOM - #12,700,000</p>
            <div className="features">
              <p>Accommodation: 4 or 2 in a room in a 5-star hotel directly opposite Haram In both Makkah & Madinah; Hilton Hotel in Makkah and PullMan ZamZam / Hilton Hotel in Madinah</p>
              <p>Airline Return Economy Ticket </p>
              <p>Visa procurement </p>
              <p>2 Buffet Meals Daily (Breakfast and Dinner)</p>
              <p>Local transportation in (Makkah & Madinah)</p>
              <p>Visitation to historical sites. </p>
              <p>Tent D Accommodation in Muna and Arafat</p>
            </div>
            <button className='bookBtn'><a href={`https://api.whatsapp.com/send?phone=${"7089451747"}&text=I%20am%20ready%20to%20embark%20on%20my%20journey%20with%20your%20company`}>Book Now</a></button>
          </div>
          </motion.div>
        </div>
        <div className="umrah">
        <img src="https://res.cloudinary.com/dvo8xhx6r/image/upload/v1702843153/umrah-2022-all-you-need-to-know-about-the-holy-pilgrimage-800x471_d68thz.jpg" alt="" />
        <div className="umrahDetails">
        <h1 className="subTitle">Umrah Packages</h1>
        <h3 className="packTitle">BASIC PACKAGE: #2,800,000 </h3>
        <h3 className="packTitle">5 STAR HOTEL: #5,400,000 </h3>
        </div>
        </div>
      </section>
    </>
  );
};

export default Services;
