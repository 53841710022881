export const homeCarusole = [
    {
        title: 'AL-AMANAT',
        subtitle: 'Travels & Services LTD',
        image: require('../components/assets/masjibNew.jpg')
    },
    {
        title: 'Ticket Booking',
        subtitle: 'Secure Your Tickets.',
        image: require('../components/assets/medina.jpg')
    },
    {
        title: 'Visas Processing',
        subtitle: 'Efficient Visa Assistance',
        image: require('../components/assets/masjid.jpg')
    },
    {
        title: 'Hotel Booking',
        subtitle: 'Book, Stay, Enjoy',
        image: require('../components/assets/paris.jpg')
    }
]