import client1 from '../components/assets/client1.jpg'
import client2 from '../components/assets/client2.jpg'

const testy = [
    {
        id : 0,
        name : 'Alhaja Bola Yusuf Manjo',
        testimonial: 'Booking through AL-AMANAT Travels & Services LTD was incredible! The staff was knowledgeable, friendly, and made the process stress-free. Thanks to them, I had the trip of a lifetime!',
        profession : 'Operation Manager @ Polaris Bank',
        url : client1
    },
    {
        id : 1,
        name : 'Alhaji Babatunde Olopo Eyan Na Hala',
        testimonial: 'I highly recommend AL-AMANAT Travels & Services LTD They exceeded my expectations, crafting a personalized itinerary and taking care of all the details. Their attention to detail is remarkable.',
        profession : 'MD Fatuns BDC and Fatuns Motor',
        url : client2
    },
    // {
    //     id : 2,
    //     name : 'Client Name',
    //     testimonial: 'AL-AMANAT Travels & Services LTD provided exceptional service for multiple trips. Their agents are professional, responsive, and offer valuable insights. Highly recommended!',
    //     profession : 'Profession',
    //     url : client2
    // }
]

export default testy;