import React from 'react'
import './Contact.css'
import { FaPhoneAlt } from 'react-icons/fa'
import {FiMail} from 'react-icons/fi'

const Contact = () => {
  return (
    <>
    <div className='contact' id='contact'>
        <h1 className='ready'>Ready to embark on your journey ?<br /><span className="Comap"><a href={`https://api.whatsapp.com/send?phone=${"7089451747"}&text=I%20am%20ready%20to%20embark%20on%20my%20journey%20with%20your%20company`}>Get it touch us!</a></span></h1>
        <div className="contactUS">
          <h2 className="call">Call us for information :  </h2>
          <h4 className="number"><FaPhoneAlt /> 08060467676</h4>
          <h4 className="number"><FaPhoneAlt /> 07089451747</h4>
          <h3>OR</h3>
          <h2 className="call">Mail us:</h2>
          <h4 className="number"><FiMail /> alamanttravels45@gmail.com</h4>
          <h3>OR</h3>
          <h2 className="call">Visit us @ our address</h2>
          <div className="address">
          <h4>Head Office</h4>
          <address>611 Agege Motor Road, Shogunle Bus stop, Ikeja, Lagos.</address>
          <h4>Ilorin Branch</h4>
          <address>13, Abdulquadri Road GRA, Ilorin opposite National Museum GRA, Ilorin</address>
          <h4>Ijebu Ode Branch</h4>
          <address>66 Ibadan Road. Between Sky Bank and Stanbic Bank, Ijebu Ode, Ogun</address>
          <h4>Abeokuta Branch</h4>
          <address>Ita Osin Central Mosque, Abeokuta, Ogun.</address>
          <h4>Osogbo Branch</h4>
          <address>Wafo Shopping Complex Opposite FCMB Bank Olosan Bus Stop, Ibadan Road, Osogbo, Osun.</address>
          <h4>Ibadan Branch</h4>
          <address>Kan Ekun Plaza Behind NNPC Filling Station, Akala Road, Ibadan, Oyo.</address>
          </div>
        </div>
    </div>
    </>
  )
}

export default Contact