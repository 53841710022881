import React from 'react'
import { FaFacebookF, FaInstagram} from 'react-icons/fa'
import colored from "../assets/colored.png"
import './Footer.css'


const Footer = () => {
  return (
    <section className='footer'>
       <div className="top">
       <div className='logoSec'>
            <img src={colored} alt="" className='footerLogo'/>
            <p>Explore dream destinations with our trusted travel agent. Unforgettable journeys tailored to your preferences, ensuring a seamless experience.</p>
            <h2 className='follow'>Follow us</h2>
            <div className="clicklable">
                <span className='footerIcon'><a href="https://www.facebook.com/AlamanatTravels?mibextid=ZbWKwL"><FaFacebookF  /></a></span>
                <span className='footerIcon'><a href="https://instagram.com/alamanattravels?igshid=ZGUzMzM3NWJiOQ=="><FaInstagram  /></a></span>
            </div>
        </div>
       </div>
       <hr />
       <div className="bottom">
        <h2>Copyright © Al-Manat. All Right Reserved</h2>
        <h2>Design by <a href="https://jafarscript.netlify.app">Jafarscript</a></h2>
       </div>
    </section>
  )
}

export default Footer

// FaLinkedinIn, FaTwitter 